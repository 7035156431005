import { httpClient } from './httpClient.js';

const
    LISTSETTLEMENTS = 'dashboard/settlements',
    GETSETTLEMENTDETAILS = 'dashboard/settlements/',
    EXPORTRECON = 'dashboard/settlements/';

const listSettlement = async (listParams) => await httpClient.dashboardService.get(LISTSETTLEMENTS, { "params": listParams });
const getSettlement = async (id) => await httpClient.dashboardService.get(GETSETTLEMENTDETAILS + id);
const exportSettlementDetails = async (id) => await httpClient.dashboardService.get(EXPORTRECON + id + '/export', { responseType: 'blob' });

export {
    listSettlement,
    getSettlement,
    exportSettlementDetails
};
