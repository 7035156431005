<template>
  <div>
    <CRow>
      <CCol col="12" xl="12">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol>
                <CInput
                  :placeholder="$t('SETTLEMENTS.SEARCH')"
                  v-model="settlementFilters.search"
                  @input="getSettlemtDetails"
                  class="px-2 float-right col-sm-2"
                />

                <CButton
                  size="sm"
                  @click="filterModal = true"
                  color="lightborder"
                  class="px-2 float-right"
                >
                  <CIcon name="cil-filter" />
                  &nbsp;{{ $t("COMMON.FILTERS") }}
                </CButton>

                <vue-json-to-csv
                  v-if="CSVData !== null"
                  :json-data="CSVData"
                  :labels="getCSVLables()"
                  :csv-title="getCSVName('settlements')"
                  class="px-2 float-right"
                >
                  <CButton
                    @click="getSettlemtDetailsForExport"
                    size="sm"
                    color="lightborder"
                  >
                    <CIcon name="cil-cloud-download" />
                    &nbsp;{{ $t("COMMON.EXPORT") }}
                  </CButton>
                </vue-json-to-csv>
              </CCol>
            </CRow>
            <CDataTable hover :items="settlements" :fields="fields">
              <template #id="data">
                <td
                  size="sm"
                  @click="goTo('/settlements/', data.item.id)"
                  class="font-weight-bold"
                >
                  {{ data.item.id }}
                </td>
              </template>
              <template #status="data">
                <td>
                  <CBadge :color="getSettlementBadge(data.item.status)">
                    {{ data.item.status }}
                  </CBadge>
                </td>
              </template>
              <template #settled_at="data">
                <td>
                  <div>{{ formatDate(data.item.settled_at) }}</div>
                </td>
              </template>
              <template #amount="data">
                <td>
                  <div>{{ formatAmount(data.item.amount) }}</div>
                </td>
              </template>
              <template #created_at="data">
                <td class="align-text-right">
                  <CButton
                    size="sm"
                    @click="getBreakDown(data.item.id)"
                    class="view-more"
                    >{{ $t("SETTLEMENTS.BREAKUP") }}</CButton
                  >
                </td>
              </template>
            </CDataTable>
            <div class="col-md-11">
              <b-pagination
                size="md"
                align="center"
                :total-rows="totalRows"
                @change="pageChange"
                v-model="pageNumber"
                :per-page="settlementFilters.limit"
              ></b-pagination>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      title="Filters"
      color="light"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="filterModal"
    >
      <div class="row">
        <label class="col-12 col-xl-12">Select Date range</label>
        <date-picker
          class="primary col-12 col-xl-12"
          v-model="dates"
          type="date"
          range
          placeholder="Select date range"
          @change="selectRange"
        ></date-picker>
      </div>
      <template #footer>
        <CButton @click="resetDateRange">{{ $t("COMMON.RESET") }}</CButton>
        <CButton @click="filterModal = false" color="durianprimary">{{
          $t("COMMON.DONE")
        }}</CButton>
      </template>
    </CModal>

    <CModal
      title="Settlement Breakdown"
      color="light"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="breakDownModal"
    >
      <CDataTable
        :header="false"
        small
        fixed
        :items="visibleData"
        :fields="detailFields"
      ></CDataTable>
      <template #footer>
        <CButton @click="breakDownModal = false" color="durianprimary">{{
          $t("COMMON.DONE")
        }}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { listSettlement, getSettlement } from "@/api/settlement.api.js";
import VueJsonToCsv from "vue-json-to-csv";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { sandboxEventEmitter } from "@/../config.js";

const COMPONENT_NAME = "Settlements";
export default {
  components: {
    VueJsonToCsv,
    DatePicker,
  },
  name: COMPONENT_NAME,
  data() {
    return {
      breakDownModal: false,
      active: "All",
      groupColumnEmpty: true,
      filterModal: false,
      visible: false,
      totalRows: 0,
      fields: [
        {
          key: "id",
          label: this.$t("SETTLEMENTS.TABLE.3"),
          _classes: "font-weight-bold",
        },
        {
          key: "total_amount",
          label: this.$t("SETTLEMENTS.TABLE.16"),
        },
        { key: "currency", label: this.$t("SETTLEMENTS.TABLE.5") },
        { key: "settled_at", label: this.$t("SETTLEMENTS.TABLE.4") },
        { key: "status", label: this.$t("SETTLEMENTS.TABLE.1") },
        { key: "created_at", label: "" },
      ],
      settlementFilters: {
        skip: 0,
        limit: 50,
        filters: [],
        from: "",
        to: "",
        search: "",
      },
      options1: [
        {
          columns: "Status",
          values: [
            { name: "paid", column: "status" },
            { name: "started", column: "status" },
            { name: "partial", column: "status" },
            { name: "processed", column: "status" },
            { name: "completed", column: "status" },
          ],
        },
      ],
      options2: [
        {
          columns: "PaymentDetailsType",
          values: [
            { name: "CA", column: "payment_details_type" },
            { name: "VA", column: "payment_details_type" },
          ],
        },
      ],
      options3: [
        {
          columns: "is_Live",
          values: [
            { name: "Live", column: "is_live" },
            { name: "Sandbox", column: "is_live" },
          ],
        },
      ],
      value1: null,
      value2: null,
      value3: null,
      settlements: [],
      selected: null,
      pageNumber: 1,
      value: "",
      valueto: "",
      today: "",
      before: "",
      CSVLables: {
        id: {},
        settled_at: {},
        currency: {},
        status: {},
        total_amount: {},
      },
      CSVData: [],
      settlement_details: {},
      id: null,
      settlements_export: [],
      range: {
        start: new Date(2020, 0, 1),
        end: new Date(),
      },
      dates: [],
    };
  },
  methods: {
    selectRange() {
      if (this.ifValid(this.dates[0]) && this.ifValid(this.dates[1])) {
        this.settlementFilters.from = this.moment(this.dates[0])
          .format("YYYY-MM-DD")
          .toString();
        this.settlementFilters.to = this.moment(this.dates[1])
          .format("YYYY-MM-DD")
          .toString();
      } else {
        this.settlementFilters.from = this.before;
        this.settlementFilters.to = this.today;
      }
      this.getSettlemtDetails();
    },
    resetDateRange() {
      this.dates = [];
      this.settlementFilters.from = "";
      this.settlementFilters.to = "";
      this.getSettlemtDetails();
    },
    pageChange(val) {
      this.settlementFilters.skip = (val - 1) * this.settlementFilters.limit;
      this.getSettlemtDetails();
    },
    removeFromSettlementFilter(val) {
      let removeIndex = -1;
      this.settlementFilters.filters.find((v, i) => {
        if (v.key === val) {
          removeIndex = i;
        }
      });
      if (removeIndex != -1) {
        this.settlementFilters.filters.splice(removeIndex, 1);
      }
    },
    selectFilter1(val) {
      this.removeFromSettlementFilter(val.column);
      let f = {};
      f.value = val.name;
      f.key = val.column;
      this.settlementFilters.filters.push(f);
      this.settlementFilters.skip = 0;
      this.getSettlemtDetails();
    },
    clearFilter1(val) {
      this.value1 = null;
      let removeElementAtIndex = -1;
      this.settlementFilters.filters.forEach(function (v, index) {
        if (v.key === val.column) {
          removeElementAtIndex = index;
        }
      });
      if (removeElementAtIndex !== -1) {
        this.settlementFilters.filters.splice(removeElementAtIndex, 1);
      }
      this.getSettlemtDetails();
    },
    selectFilter2(val) {
      this.removeFromSettlementFilter(val.column);
      let f = {};
      if (val.name == "CA") {
        f.value = "card_details";
      } else {
        f.value = "va_details";
      }
      f.key = val.column;
      this.settlementFilters.filters.push(f);
      this.settlementFilters.skip = 0;
      this.getSettlemtDetails();
    },
    clearFilter2(val) {
      this.value2 = null;
      let removeElementAtIndex = -1;
      this.settlementFilters.filters.forEach(function (v, index) {
        if (v.key === val.column) {
          removeElementAtIndex = index;
        }
      });
      if (removeElementAtIndex !== -1) {
        this.settlementFilters.filters.splice(removeElementAtIndex, 1);
      }
      this.getSettlemtDetails();
    },
    selectFilter3(val) {
      this.removeFromSettlementFilter(val.column);
      let f = {};
      if (val.name == "Live") {
        f.value = "true";
      } else {
        f.value = "false";
      }
      f.key = val.column;
      this.settlementFilters.filters.push(f);
      this.settlementFilters.skip = 0;
      this.getSettlemtDetails();
    },
    clearFilter3(val) {
      this.value3 = null;
      let removeElementAtIndex = -1;
      this.settlementFilters.filters.forEach(function (v, index) {
        if (v.key === val.column) {
          removeElementAtIndex = index;
        }
      });
      if (removeElementAtIndex !== -1) {
        this.settlementFilters.filters.splice(removeElementAtIndex, 1);
      }
      this.getSettlemtDetails();
    },
    getBreakDown(id) {
      getSettlement(id)
        .then((response) => {
          this.id = id;
          this.settlement_details = response.data.data;
          let { totalAmount, totalDiscount } = this.getTotalAmounts();
          this.settlement_details["discount"] = this.formatAmount(
            totalDiscount
          );
          this.settlement_details["total_settlement_fee"] = this.formatAmount(
            this.settlement_details["total_settlement_fee"]
          );
          this.settlement_details["settlement_amount"] = this.formatAmount(
            this.settlement_details["settlement_amount"]
          );
          this.settlement_details["total_amount"] = this.formatAmount(
            totalAmount
          );

          this.breakDownModal = true;
        })
        .catch((e) => {
          if (e.response.data.error) this.showToaster(e.response.data.error);
        });
    },
    formatSettlementDetails() {
      let includeInSequence = [
        "id",
        "total_amount",
        "total_settlement_fee",
        "discount",
        "settlement_amount",
      ];
      let result = [];
      includeInSequence.forEach((key) => {
        let data = {
          key: this.getLabelKey(key),
          value: this.settlement_details[key],
        };
        result.push(data);
      });
      return result || [{ id: "Not found" }];
    },
    getTotalAmounts() {
      let totalAmount = 0;
      let totalDiscount = 0;
      this.settlement_details["payment_details"].forEach((payment) => {
        totalAmount += Number(payment["payment_amount"]);
        totalDiscount += Number(payment["payment_discount"]);
      });
      return { totalAmount, totalDiscount };
    },
    // if all the groups are set to default value then no need to show "Settlement Group" table
    // we should return true if no settlement is present
    checkGroupColumnEmpty(settlements) {
      if (!settlements || settlements === null || settlements.length === 0) {
        return true;
      }
      const length = settlements.length;
      for (let key = 0; key < length; key++) {
        if (settlements[key].group.length > 0) {
          return false;
        }
      }
      return true;
    },
    checkGroupColumnPresent() {
      for (let index in this.fields) {
        if (this.fields[index].key === "group") {
          return true;
        }
      }
      return false;
    },
    cleanSettlementAmount(settlements) {
      for (let key in settlements) {
        settlements[key]["total_amount"] = this.formatAmount(
          settlements[key]["total_amount"]
        );
        settlements[key]["settled_at"] = this.formatDate(
          settlements[key]["settled_at"]
        );
      }
    },
    async getSettlemtDetails() {
      const { skip, limit, search, filters, from, to } = this.settlementFilters;
      let paramsMap = new Map();
      paramsMap["skip"] = skip;
      paramsMap["limit"] = limit;
      paramsMap["from"] = from || this.before;
      paramsMap["to"] = to || this.today;
      paramsMap["search"] = search;
      paramsMap["filters"] = JSON.stringify(filters);
      try {
        const response = await listSettlement(
          this.generatePostParams(paramsMap)
        );
        if (!this.verifyHttpResponse(response)) {
          this.settlements = response.data.data.settlements;
          this.totalRows = response.data.data.count;
          this.cleanSettlementAmount(this.settlements);

          this.groupColumnEmpty = this.checkGroupColumnEmpty(this.settlements);
          if (!this.groupColumnEmpty && !this.checkGroupColumnPresent()) {
            const fieldObject = {
              key: "group",
              label: this.$t("SETTLEMENTS.TABLE.15"),
            };
            this.fields.splice(this.fields.length - 1, 0, fieldObject);
          }
        }
      } catch (error) {
        if (error.response.data.error)
          this.showToaster(error.response.data.error);
      }
    },
    getCSVLables() {
      this.CSVLables.id.title = this.$t("SETTLEMENTS.TABLE.3");
      this.CSVLables.currency.title = this.$t("SETTLEMENTS.TABLE.5");
      this.CSVLables.status.title = this.$t("SETTLEMENTS.TABLE.1");
      this.CSVLables.settled_at.title = this.$t("SETTLEMENTS.TABLE.4");
      this.CSVLables.total_amount.title = this.$t("SETTLEMENTS.TABLE.16");
      if (!this.groupColumnEmpty) {
        this.CSVLables.group = new Object();
        this.CSVLables.group.title = this.$t("SETTLEMENTS.TABLE.15");
      }
      return this.CSVLables;
    },
    initialiseExportData() {
      let paramsMap = new Map();
      paramsMap["skip"] = 0;
      paramsMap["limit"] = this.totalRows;
      paramsMap["from"] = this.before;
      paramsMap["to"] = this.today;
      paramsMap["search"] = "";
      paramsMap["filters"] = JSON.stringify(this.settlementFilters.filters);
      listSettlement(this.generatePostParams(paramsMap))
        .then((response) => {
          if (!this.verifyHttpResponse(response))
            this.CSVData = response.data.data.settlements;
        })
        .catch((e) => {
          if (e) this.showToaster(e);
        });
    },
    getSettlemtDetailsForExport() {
      this.cleanCSVData();
    },
    cleanCSVData() {
      this.CSVData = this.settlements;
      if (this.CSVData && this.CSVData.length > 0) {
        for (let i = 0; i < this.CSVData.length; i++) {
          this.CSVData[i].settled_at = this.formatDate(
            this.CSVData[i].settled_at
          );
          this.CSVData[i].updated_at = this.formatDate(
            this.CSVData[i].updated_at
          );
        }
      }
    },
    selectStatus(val) {
      this.active = val;
      let filter = {};
      filter.column = "status";
      filter.name = val;
      if (val != "All") {
        this.selectFilter1(filter);
      } else {
        this.clearFilter1(filter);
      }
    },
    getLabelKey(key) {
      switch (key) {
        case "id":
          return "ID";
        case "total_amount":
          return this.$t("SETTLEMENTS.TABLE.6");
        case "settlement_amount":
          return this.$t("SETTLEMENTS.TABLE.7");
        case "total_settlement_fee":
          return this.$t("SETTLEMENTS.TABLE.8");
        case "discount":
          return this.$t("SETTLEMENTS.TABLE.19");
        case "settlement_fee":
          return this.$t("SETTLEMENTS.TABLE.9");
        default:
          return key;
      }
    },
    async initializeData() {
      const dates = await this.initializeDates();
      this.today = dates.today;
      this.before = dates.before;
      this.settlementFilters.to = this.today;
      this.settlementFilters.from = this.before;
      await this.getSettlemtDetails();
      await this.initialiseExportData();
    },
  },
  computed: {
    visibleData() {
      return this.formatSettlementDetails();
    },
    detailFields() {
      if (this.breakDownModal) {
        return [
          { key: "key", label: "" },
          { key: "value", label: "" },
        ];
      }
      return [];
    },
  },
  mounted() {
    this.initializeData();
  },
  created() {
    this.$eventHub.$on(
      sandboxEventEmitter.prefix + COMPONENT_NAME,
      this.initializeData
    );
  },
  beforeDestroy() {
    this.$eventHub.$off(sandboxEventEmitter.prefix + COMPONENT_NAME);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
